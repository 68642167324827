import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { ChevronLeft } from "react-feather";
import { BlogPostTemplate } from "./blog-post";
import Tag from "../components/Tag";
import ImagePortal from "../components/ImagePortal";
import LinkFade from "../components/animation/LinkFade";

export const PortfolioEntryTemplate = ({
  content,
  contentComponent,
  client,
  timeframe,
  media,
  tags,
  title,
  helmet,
  date,
}) => {
  const PostContent = contentComponent || Content
  // media = [...media, ...media]

  return (
    <section className="section blog-post">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1 blog-post">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              <LinkFade duration={0.2} className="caret-back" to="/portfolio">
                <ChevronLeft />
              </LinkFade>
              {title}
            </h1>
            <div className="description">
              <small>{client} | {timeframe}</small>
            </div>
            {tags && tags.length ? (
              <div className="tags">
                <ul className="taglist">
                  {tags.map(tag => (
                    <Tag key={tag + `tag`} className="has-cursor">
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </Tag>
                  ))}
                </ul>
              </div>
            ) : null}
            <PostContent content={content} />
            <div className="portfolio-media-wrapper">
              <div className="media-entry-grid">
                {media.map(mediaEntry => (
                  <div className="media-entry" key={mediaEntry.description}>
                    <div className={`media-main ${mediaEntry.image ? 'can-enlarge' : null}`}>
                      {mediaEntry.image && (
                        <ImagePortal images={[mediaEntry.image.publicURL]}>
                          <img src={mediaEntry.image.publicURL} />
                        </ImagePortal>
                      )}
                      {mediaEntry.video && (
                        <figure className="image 16by9">
                          <iframe className="has-ratio" width="640" height="360" src={mediaEntry.video} frameBorder="0"
                                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen></iframe>
                        </figure>
                      )}
                    </div>
                    <div className="media-description">
                      {mediaEntry.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

PortfolioEntryTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  client: PropTypes.string,
  timeframe: PropTypes.string,
  media: PropTypes.array,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const PortfolioEntry = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <PortfolioEntryTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet
            titleTemplate="%s | Portfolio"
          >
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
        client={post.frontmatter.client}
        timeframe={post.frontmatter.timeframe}
        media={post.frontmatter.media}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

PortfolioEntry.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default PortfolioEntry

export const pageQuery = graphql`
  query PortfolioEntryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        client
        timeframe
        media {
          image {
            publicURL
          }
          video
          description
        }
        tags
      }
    }
  }
`
