import React, { Component, createRef } from "react";
import Carousel, { Modal, ModalGateway } from 'react-images';
import PropTypes from "prop-types";

class ImagePortal extends Component {
  state = {
    currentModal: null
  }
  wrapperRef = createRef()

  toggleModal = () => {
    this.setState({currentModal: this.state.currentModal === 0 ? null : 0})
  }

  render = () => {
    const imagesSrc = this.props.images.map(i => ({ source: i, path: i }))
    return (

    <>
      <figure className="image" ref={this.wrapperRef} onClick={this.toggleModal}>
        {this.props.children}
      </figure>

      <ModalGateway>
        {Number.isInteger(this.state.currentModal) ? (
          <Modal
            allowFullscreen={false}
            closeOnBackdropClick={true}
            onClose={this.toggleModal}
            styles={{
              blanket: base => ({
                ...base,
                backgroundColor: 'rgba(0,0,0,0.35)',
              }),
              positioner: base => ({
                ...base,
                display: 'block',
              }),
            }}
          >
            <Carousel
              currentIndex={this.state.currentModal}
              components={{ Footer: null, Header: null }}
              frameProps={{ autoSize: 'height' }}
              views={imagesSrc}
              styles={{
                container: base => ({
                  ...base,
                  height: '100vh',
                }),
                view: base => ({
                  ...base,
                  alignItems: 'center',
                  display: 'flex ',
                  height: '100vh',
                  justifyContent: 'center',

                  [1200]: {
                    padding: 20,
                  },

                  '& > img': {
                    maxHeight: 'calc(100vh - 94px)',
                  },
                }),
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )};
};

ImagePortal.propTypes = {
  src: PropTypes.string,
};

export default ImagePortal;
