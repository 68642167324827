import React from "react";
import PropTypes from "prop-types";

const Tag = ({ icon, children, className, size, ...restProps }) => {

  const sizeStyle = `is-medium`
  const tagStyle = `tag default-tag ${sizeStyle} ${className}`

  return (
    <span className={tagStyle} {...restProps}>
      {children}
    </span>
  );
};

Tag.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default Tag;
